






























































































































































































































































































































import {sleep} from '@/api'
import {truncate} from '@/helpers'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import {RawLocation, Route} from 'vue-router'
import {convertToDate, formatDate} from '@/helpers/formatDate'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {projectModule} from '@/store/index'

import {WidgetResource} from '@/models/widgets/WidgetResource'
import {ProcessResource} from '@/models/process/ProcessResource'
import {SuggestionRequest} from '@/requests/suggestions/SuggestionRequest'
import {ProposalUpdateRequest} from '@/requests/proposals/ProposalUpdateRequest'
import {StatusResourceCollection} from '@/models/status/StatusResourceCollection'
import {ProposalStatusPatchRequest} from '@/requests/proposals/ProposalStatusPatchRequest'
import {ProposalAllocateUsersRequest} from '@/requests/proposals/ProposalAllocateUsersRequest'
import {linkedProposal, ProposalCollectionResource} from '@/models/proposals/ProposalCollectionResource'
import {DocumentResource} from '@/models/documents/DocumentResource'
import {ProjectResource} from '@/models/projects/ProjectResource'

import Users from '@/components/list/Users.vue'
import Modal from '@/components/modals/Default.vue'
import Submit from '@/components/project/Submit.vue'
import TextInput from '@/components/inputs/Text.vue'
import WysiwygInput from '@/components/editors/Wysiwyg.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import StatusBadge from '@/components/statuses/StatusBadge.vue'
import StatusPicker from '@/components/inputs/StatusPicker.vue'
import ResponseModal from '@/components/widgets/ResponseModal.vue'
import WidgetList from '@/components/proposal/create/WidgetList.vue'
import CreateProposalDocumentViewer from '@/components/proposal/modals/CreateProposalDocumentViewer.vue'

type RouterLocation = RawLocation
type ClonedRoute = Route

@Component({
  name: 'ProposalCard',
  components: {
    Users,
    UserPicker,
    ResponseModal,
    StatusPicker,
    Modal,
    Submit,
    TextInput,
    WidgetList,
    StatusBadge,
    SmallLoader,
    WysiwygInput,
    CreateProposalDocumentViewer,
  }
})
export default class ProposalCard extends Vue {
  public proposalId: any = null

  @Prop()
  private data!: ProposalCollectionResource

  @Prop({default: false})
  private isDisabled!: boolean

  @Prop({default: false})
  private isSelected!: boolean

  @Prop({default: true})
  private canSelectMultiple!: boolean

  @Prop({default: false})
  private readonly!: boolean

  @Prop({default: null})
  private process!: ProcessResource | null

  @Prop({default: false})
  private canSelect!: boolean

  @Prop({default: false})
  private semiDisabled!: boolean

  @Prop({default: true})
  private canNavigate!: boolean

  @Prop({default: true})
  private canRedirect!: boolean

  @Prop({default: false})
  private small!: boolean

  @Prop({default: false})
  private canCopy!: boolean

  @Prop({default: false})
  private noConnections!: boolean

  @Prop({default: ''})
  private elementId!: string

  @Prop({default: () => []})
  private readonly blacklistedComponentIds!: number[]

  @Prop({default: () => []})
  private selectedItems!: number | number[]

  private loading: boolean = false
  private edit: boolean = false
  private suggest: boolean = false
  private editWarningModalShown: boolean = false

  private deleteWarningModalShown: boolean = false
  private deleting: boolean = false

  private form: ProposalUpdateRequest = new ProposalUpdateRequest(this.data)
  private modalDocument: any = null

  private isCreateProposalModalOpen: boolean = false
  private createProposalModal: boolean = false

  private changeStatusModal: {
    loading: boolean
    open: boolean
    status: StatusResourceCollection | null
  } = {
    loading: false,
    open: false,
    status: null
  }

  private responseModal: {
    open: boolean
    status: StatusResourceCollection | null
  } = {
    open: false,
    status: null
  }

  private allocatedUsersForm: { form: ProposalAllocateUsersRequest, loading: boolean } = {
    loading: false,
    form: new ProposalAllocateUsersRequest(this.data)
  }

  private get allocatedUserIds() {
    return this.data.allocated_users.map(({id}) => id)
  }

  private get allocationChanged() {
    const initialForm = new ProposalAllocateUsersRequest(this.data)
    return !isEqual(initialForm.notify_user, this.allocatedUsersForm.form.notify_user)
  }

  @Watch('$route')
  private onRouteChange(val: ClonedRoute, oldVal: ClonedRoute): void {
    if (val.name !== oldVal.name &&
        val.name !== 'projects-flexible-document-proposal' &&
        val.name !== 'projects-detail-document-detail' &&
        val.name !== 'projects-detail-document-proposal-detail' &&
        val.name !== 'projects-detail-proposal-detail') {
      this.edit = false
      this.suggest = false
    }
  }

  @Watch('edit')
  private onEditChange(val: boolean): void {
    if (val) {
      this.goToProposalLink()
      this.$router.push({query: {editId: this.data.id.toString()}})
      this.$emit('editing', true)
      this.suggest = false
    } else {
      this.$router.push({query: {editId: null}})
      this.$emit('editing', false)
    }
  }

  @Watch('suggest')
  private onSuggestChange(val: boolean): void {
    if (val) {
      this.goToProposalLink({ suggestId: this.data.id.toString(), page: 'suggestions'})
      this.edit = false
    } else {
      this.$router.push({query: {suggestId: null}})
    }
  }

  @Watch('$route.query')
  private onRouteQueryChange(query: Dictionary<string>): void {
    if (query.editId && +query.editId !== this.data.id && this.edit) {
      this.edit = false
    }
    if (query.suggestId && +query.suggestId !== this.data.id && this.suggest) {
      this.suggest = false
    }
  }

  @Watch('$route.params')
  private onRouteParamsChange(params: Dictionary<string>): void {
    if (params.proposal_id && +params.proposal_id !== this.data.id && this.edit) {
      this.edit = false
    }
    if (params.proposal_id && +params.proposal_id !== this.data.id && this.suggest) {
      this.suggest = false
    }
  }

  private get project(): ProjectResource | null {
    return projectModule.detail
  }

  private get isMultiSelectActive(): boolean {
    return isNumber(this.selectedItems) || (isArray(this.selectedItems) && this.selectedItems.length > 0) &&
        !(isArray(this.selectedItems) && this.selectedItems.length === 1 && this.isSelected)
  }

  private get showFooter(): boolean {
    return this.data.allocated_users.length > 0 || !this.noConnections
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get isOnDocument(): boolean {
    return this.$route.name?.includes('projects-detail-document') ?? false
  }

  private get isOnFlexibleDocument(): boolean {
    return this.$route && this.$route.name
        ? this.$route.name === 'projects-flexible-document-element' ||
        this.$route.name === 'projects-flexible-document' ||
        this.$route.name === 'projects-flexible-document-proposal'
        : false
  }

  private get isOnProcess(): boolean {
    return this.$route.name === 'projects-detail-process'
  }

  private get isOnProposal(): boolean {
    return this.$route.name === 'projects-detail-proposal-detail'
  }

  private get proposalCommentRoute(): any {
    if (this.isOnFlexibleDocument) {
      return {
        name: 'projects-flexible-document-proposal',
        params: {process_id: this.data.process_id, element_id: this.elementId, proposal_id: this.data.id},
        query: {page: 'comments'}
      }
    } else if (this.isOnDocument) {
      return {
        name: 'projects-detail-document',
        params: {
          project_id: this.process?.project_id.toString() ?? '',
          document_id: this.$route.params.document_id,
          proposal_id: this.data.id
        }
      }
    } else {
      return {
        name: 'projects-detail-proposal-detail',
        params: {process_id: this.data.process_id, proposal_id: this.data.id},
        query: {page: 'comments'}
      }
    }
  }

  private get classes(): Dictionary<boolean> {
    return {
      'disabled': this.isDisabled,
      'semi-disabled': this.semiDisabled,
      'can-select': this.canSelect || this.data.is_locked,
      'cantNavigate': !this.canNavigate,
      'small': this.small,
      'can-copy': this.canCopy,
      'active': this.active,
      'no-connections': this.noConnections,
      'edit-mode': this.edit || this.suggest
    }
  }

  private get style(): Dictionary<string> {
    const styles: Dictionary<string> = {}
    if (this.active) {
      styles.borderColor = this.data.status.color
    }
    return styles
  }

  // prev connections
  private get showPreviousConnections(): boolean {
    return (this.previousProposals?.length ?? 0) > 0
  }

  private get showDocumentLinks(): boolean {
    return !!this.data.document_id || !!this.data.document_link
  }

  private get showPrevConnectionsEmpty(): boolean {
    return (this.previousProposals?.length ?? 0) === 0 && !this.readonly && !!this.previousProcess
  }

  // Next connections
  private get showNextConnections(): boolean {
    return (this.nextProposals?.length ?? 0) > 0
  }

  private get showNextConnectionsEmpty(): boolean {
    return (this.nextProposals?.length ?? 0) === 0 && !!this.nextProcess && !this.readonly
  }

  private get widgets(): WidgetResource[] | null {
    return this.process ? this.process.widgets : null
  }

  private get previousProposals(): linkedProposal[] | null {
    return this.data.previous_linked_proposals || null
  }

  private get nextProposals(): linkedProposal[] | null {
    return this.data.next_linked_proposals || null
  }

  private get isActive(): boolean {
    return `${this.$route.params.proposal_id}` === `${this.data.id}`
  }

  private get canEdit(): boolean {
    return (!this.readonly && this.data.canPerformAction('can_edit_proposal') && !this.data.is_locked)
  }

  private get canSuggest(): boolean {
    return (!this.readonly && this.data.canPerformAction('can_suggest'))
  }

  private get selectComponent(): string {
    if (this.canSelectMultiple) {
      return this.isSelected ? 'selectedBoxIcon' : 'selectBoxIcon'
    }
    return this.isSelected ? 'radiobuttonActiveIcon' : 'radiobuttonIcon'
  }

  private get active(): boolean {
    return parseInt(this.$route.params.proposal_id, 10) === this.data.id
  }

  private get simlarityText(): string {
    if (this.data.similarity_score) {
      if (this.data.similarity_score > 90) {
        return 'Similarity level: Very similar'
      } else if (this.data.similarity_score < 90 && this.data.similarity_score >= 80) {
        return 'Similarity level: Similar'
      } else if (this.data.similarity_score < 80) {
        return 'Similarity level: Slightly similar'
      }
    }

    return ''
  }

  private get overviewData(): string {
    if (this.data.proposal_value_overview) {
      const div = document.createElement('div')
      div.innerHTML = this.data.proposal_value_overview.trim()

      return truncate(div.innerText, 140)
    }

    return ''
  }

  private beforeDestroy(): void {
    this.data.destroy()
  }

  private setSelected(): void {
    if (!this.canSelectMultiple && this.isSelected) {
      return
    }
    this.$emit('select', this.data)
  }

  private clickHandler(event: MouseEvent): void {
    const TARGET = event.target as HTMLElement

    // Don't trigger the click event on a button on the card
    if (TARGET.classList.contains('card-action-button')) {
      return
    }

    if (!this.small && !this.edit && !this.canCopy ||
        (this.canCopy && TARGET.nodeName === 'H2')
    ) {
      this.$emit('click', this.data, event)
    }
  }

  private goToProposal(proposal: linkedProposal): void {
    if (this.process && this.canRedirect) {
      this.$router.push({
        name: 'projects-detail-proposal-detail',
        params: {
          process_id: proposal.process_id.toString(),
          proposal_id: proposal.proposal_id.toString(),
          project_id: this.process.project_id.toString()
        }
      })
    }
  }

  private get previousProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process?.order ?? 0) - 1 ?? 0)
  }

  private get nextProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process?.order ?? 0) + 1 ?? 0)
  }

  private async patchHandler(): Promise<void> {
    this.loading = true
    try {
      await this.data.patch(this.form)
    } catch (e) {
      throw e
    } finally {
      this.loading = false
      this.edit = false
      this.$emit('update')
    }
  }

  private async suggestHandler(): Promise<void> {
    this.loading = true
    
    try {
      if (this.process) {
        const FORM = this.form as SuggestionRequest
        FORM.suggestible_type = 'proposal'
        FORM.suggestible_id = this.data.id
        FORM.project_id = this.process.project_id
        FORM.status = 'unresolved'

        await this.data.suggestions.post(this.form)
      }
    } catch (e) {
      throw e
    } finally {
      this.loading = false
      this.cancelSuggest()
      this.$emit('update')
    }
  }

  private setSuggesting(): void {
    this.suggest = !this.suggest
  }

  private setEditing(): void {
    if (!this.data.inherited) {
      this.edit = !this.edit
    } else {
      this.editWarningModalShown = true
    }
  }

  private goToProposalLink(queryObject: Dictionary<string> = {}): void {
    if (+this.$route.params.proposal_id !== this.data.id) {
      if (this.isOnProcess || this.isOnProposal) {
        this.$router.push({
          name: 'projects-detail-proposal-detail',
          params: {
            ...this.$route.params,
            process_id: `${this.data.process_id}`,
            proposal_id: `${this.data.id}`
          },
          query: queryObject
        })
      } else if (this.isOnFlexibleDocument) {
        this.$router.push({
          name: 'projects-flexible-document-proposal',
          params: {process_id: `${this.data.process_id}`, element_id: this.elementId, proposal_id: `${this.data.id}`},
          query: queryObject
        })
      } else if (this.isOnDocument) {
        this.$router.push({
          name: 'projects-detail-document-proposal-detail',
          params: {
            project_id: this.$route.params.project_id,
            document_id: this.$route.params.document_id,
            proposal_id: `${this.data.id}`
          },
          query: queryObject
        })
      }
    }
  }

  private overrideEditWarning(): void {
    this.editWarningModalShown = false
    this.edit = true
  }

  private cancelEdit(): void {
    this.edit = false
    this.form = new ProposalUpdateRequest(this.data)
  }

  private cancelSuggest(): void {
    this.suggest = false
    this.form = new ProposalUpdateRequest(this.data)
  }

  private formatArray(selectionArray: string): string {
    if (selectionArray.length > 0) {
      const array = JSON.parse(selectionArray)
      if (array.length > 0) {
        return array.join(', ')
      }
    }

    return ''
  }

  private confirmDelete(): void {
    this.deleteWarningModalShown = true
  }

  private cancelDelete(): void {
    this.deleteWarningModalShown = false
  }

  private async deleteProposal(): Promise<void> {
    this.deleting = true
    try {
      await this.data.delete()
      this.deleteWarningModalShown = false
      this.$emit('delete')
      if (this.isOnDocument) {
        await this.$router.push({
          name: 'projects-detail-document',
          params: {
            project_id: this.process?.project_id.toString() ?? '',
            document_id: this.$route.params.document_id
          }
        })
        this.modifyDomProposalStatus(this.data.id)
      }
      if (this.isOnFlexibleDocument) {
        await this.$router.push({
          name: 'projects-flexible-document',
          params: {project_id: this.process?.project_id.toString() ?? ''}
        })
      }
      if (this.isOnProcess || this.isOnProposal) {
        await this.$router.push({
          name: 'projects-detail-process',
          params: {
            project_id: this.process?.project_id.toString() ?? '',
            process_id: this.process?.id.toString() ?? ''
          }
        })
      }

    } finally {
      this.deleting = false
    }
  }

  private modifyDomProposalStatus(proposalId: number, status?: string): void {
    const proposalSpans = document.querySelectorAll(
        `span[data-proposal-id="${proposalId}"]`
    )

    for (const span of proposalSpans) {
      span.classList.remove(
          'defined',
          'submitted',
          'approved',
          'declined',
          'released'
      )

      if (span.classList.contains('inherited') && !status) {
        span.setAttribute('data-inherited', 'true')
      } else {
        span.removeAttribute('data-proposal-id')
        span.removeAttribute('data-inherited')
      }
      if (status) {
        span.classList.add(status.toLowerCase())
        span.setAttribute('data-proposal-id', proposalId.toString())
      } else {
        span.classList.remove('proposal')
      }
    }
  }

  private goToUrl(url: string): void {
    window.open(url, '_blank')
  }

  private formatDate(date: string): string {
    return date.length > 0 ? formatDate(convertToDate(date)) : ''
  }

  private getProposalTitles(proposals: linkedProposal[]): string {
    return proposals?.map((proposal) => proposal.proposal_name).join('<br />') ?? ''
  }

  private addHover(): void {
    const nodes = document.getElementsByClassName(`proposal-id-${this.data.id}`)
    for (const node of nodes) {
      node.classList.add('hover')
    }
  }

  private removeHover(): void {
    const nodes = document.getElementsByClassName(`proposal-id-${this.data.id}`)
    for (const node of nodes) {
      node.classList.remove('hover')
    }
  }

  private startUpdateStatusProcess(statusValue: string) {
    this.changeStatusModal.status = this.process?.statuses.find((status) => statusValue === status.value) ?? null
    if (this.changeStatusModal.status) {
      this.changeStatusModal.open = true
    }
  }

  private closeChangeStatusModal() {
    this.changeStatusModal.open = false
  }

  private async patchStatus(form: { message?: string, userIds?: number[] }): Promise<void> {
    if (!this.changeStatusModal.status || !this.changeStatusModal.status.value) {
      return
    }

    this.changeStatusModal.loading = true

    const body: ProposalStatusPatchRequest = new ProposalStatusPatchRequest({
      status_message: form.message,
      notify_user: form.userIds,
      status: this.changeStatusModal.status.value
    })

    try {
      await this.data.patchStatus(body)
      this.closeChangeStatusModal()
      this.openResponseModal()
      this.allocatedUsersForm.form = new ProposalAllocateUsersRequest(this.data)
    } catch (e) {
      console.error(e)
    } finally {
      this.changeStatusModal.loading = false
    }
  }

  private openResponseModal(): void {
    this.responseModal.status = this.data.status
    this.responseModal.open = true
  }

  private closeResponseModal(): void {
    this.responseModal.open = false
    this.responseModal.status = null
  }

  private async openCreateProposalModal(): Promise<void> {
    if (!this.project) return

    try {
      const btnNames = ['Assessment', 'Assessments'];
      const specName = this.nextProcess?.singular_name || '';

      if (btnNames.includes(specName) && !this.data.document_id) {

        this.modalDocument = {
          data: {
            id: this.data.id,
            subject: '',
            name: this.data.proposal_name,
            link: '',
            version: '',
            type: '',
            issuer: '',
            country: '',
            theme: '',
            service_line: '',
            publish_date: '',
            created_at: '',
            implementation_date: '',
            consultation_date: '',
            end_date: '',
            industry: '',
            topic: [],
            segment: [],
            tags: [],
            bookmarked: false,
            relevance: '',
            has_changes: false,
            embedment: 0,
            comments: [],
            document: this.data.description,
            content: '',
            proposal_id: this.data.id,
            permissions: [],
            previous_versions: [],
            projects: []
          }
        }

        this.loading = false
        this.proposalId = this.data.id
      } else {
        this.loading = true
        this.modalDocument = await this.project.getDocument(this.data.document_id)
      }

      await this.$nextTick()
      this.createProposalModal = true
      await this.$nextTick()
      this.isCreateProposalModalOpen = true
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  private async closeCreateProposalModal(): Promise<void> {
    this.isCreateProposalModalOpen = false
    await sleep(350)
    this.createProposalModal = false
    this.modalDocument = null
  }

  // Everytime we open the dropdown we set the form since the data could be changes due to websocket updates
  private setAllocationForm() {
    this.allocatedUsersForm.form = new ProposalAllocateUsersRequest(this.data)
  }

  private cancelAllocation(toggle: () => void) {
    this.setAllocationForm()
    toggle()
  }

  private async allocateUsers(toggle: () => void) {
    this.allocatedUsersForm.loading = true
    try {
      await this.data.allocateUsers(this.allocatedUsersForm.form)
      this.refreshProcess()
      toggle()
    } catch (e) {
      console.error(e)
    } finally {
      this.allocatedUsersForm.loading = false
    }
  }

  private async toggleLock() {
    this.loading = true
    try {
      await this.data.lock()
    } finally {
      this.loading = false
    }
  }

  private refreshProcess() {
    this.process?.getProposalsIndex()
    this.process?.getProposals({})
    const query = this.$route.query
    this.$router.replace({ query: { refresh: 'true', ...query } })
  }

}
