import { AxiosPromise } from 'axios'
import { BaseService }  from '@/services/base'

import { IDocumentPresetResource }            from '@/models/documents/DocumentPresetResource'
import {
  IOrganisationDocumentResource,
  OrganisationDocumentResource
}                                             from '@/models/organisationDocuments/OrganisationDocumentResource'
import { OrganisationDocumentPresetResource } from '@/models/organisationDocuments/OrganisationDocumentPresetResource'
import {
  IOrganisationDocumentFilterResource,
  OrganisationDocumentFilterResource
}                                             from '@/models/organisationDocuments/OrganisationDocumentFilterResource'
import {
  IOrganisationDocumentCollectionResource,
  OrganisationDocumentCollectionResource
}                                             from '@/models/organisationDocuments/OrganisationDocumentCollectionResource'

import { DocumentIndexRequest }        from '@/requests/documents/DocumentIndexRequest'
import { DocumentPatchRequest }        from '@/requests/documents/DocumentPatchRequest'
import { DocumentPresetPatchRequest }  from '@/requests/documents/DocumentPresetPatchRequest'
import { DocumentPresetCreateRequest } from '@/requests/documents/DocumentPresetCreateRequest'

export class OrganisationDocumentsService extends BaseService {

  constructor() {
    super('organisation/documents')
  }

  /**
   * Get all documents by params
   */
  public async get(params: DocumentIndexRequest): Promise<IndexResponse<OrganisationDocumentCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IOrganisationDocumentCollectionResource>>(`${this.namespace}`, { params })

    data.data = data.data.map((item: any) => {
      return {
        ...item,
        project_name: item.project_info.name
      }
    })

    const element = {
      key: 'project_name',
      show: true,
      sortable: true,
      type: 'string'
    }
    data.elements?.splice(2, 0, element);

    return {
      ...data,
      data: data.data.map((document: IOrganisationDocumentCollectionResource) => new OrganisationDocumentCollectionResource(document))
    }
  }

  /**
   * Get filters of overview
   */
  public async filters(): Promise<IndexResponse<OrganisationDocumentFilterResource>> {
    const { data } = await this.client.get<IndexResponse<IOrganisationDocumentFilterResource>>(`${this.namespace}/filters`)
    data.data = data.data.map((filter: IOrganisationDocumentFilterResource) => new OrganisationDocumentFilterResource(filter))
    return data as IndexResponse<OrganisationDocumentFilterResource>
  }

  public async searchedFilters(params?: DocumentIndexRequest, key?: string): Promise<IndexResponse<OrganisationDocumentFilterResource>> {
    if (key && params) {
      params.key = key
    }
    const { data } = await this.client.get<IndexResponse<IOrganisationDocumentFilterResource>>(`${this.namespace}/search-filters`, { params })
    data.data = data.data.map((filter: IOrganisationDocumentFilterResource) => new OrganisationDocumentFilterResource(filter))
    return data as IndexResponse<OrganisationDocumentFilterResource>
  }

  /**
   * Get all presets
   */
  public async presets(): Promise<IndexResponse<OrganisationDocumentPresetResource>> {
    const { data } = await this.client.get<IndexResponse<IDocumentPresetResource>>(`${this.namespace}/presets`)
    data.data = data.data.map((preset: IDocumentPresetResource) => new OrganisationDocumentPresetResource(preset))
    return data as IndexResponse<OrganisationDocumentPresetResource>
  }

  /**
   * Post a new preset
   */
  public async postPreset(preset: DocumentPresetCreateRequest): Promise<DetailResponse<OrganisationDocumentPresetResource>> {
    const { data } = await this.client.post<DetailResponse<IDocumentPresetResource>>(`${this.namespace}/presets`, preset)

    data.data = new OrganisationDocumentPresetResource(data.data)
    return data as DetailResponse<OrganisationDocumentPresetResource>
  }

  /**
   * Patch preset
   */
  public async patchPreset(id: number, preset: DocumentPresetPatchRequest): Promise<DetailResponse<OrganisationDocumentPresetResource>> {
    const { data } = await this.client.patch<DetailResponse<IDocumentPresetResource>>(`${this.namespace}/presets/${id}`, preset)
    data.data = new OrganisationDocumentPresetResource(data.data)
    return data as DetailResponse<OrganisationDocumentPresetResource>
  }

  /**
   * Delete preset
   */
  public deletePreset(key: string): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/presets/${key}`)
  }

  /**
   * Get single document by id
   */
  public async getById(id: number): Promise<DetailResponse<OrganisationDocumentResource>> {
    const { data } = await this.client.get<DetailResponse<IOrganisationDocumentResource>>(`${this.namespace}/${id}`)
    return {
      ...data,
      data: new OrganisationDocumentResource(data.data)
    }
  }

  /**
   * Bookmark a single document by ID
   */
  public async patch(id: number, body: DocumentPatchRequest): Promise<DetailResponse<OrganisationDocumentResource>> {
    const { data } = await this.client.patch<DetailResponse<IOrganisationDocumentResource>>(`${this.namespace}/${id}`, body)
    return {
      ...data,
      data: new OrganisationDocumentResource(data.data)
    }
  }

  /**
   * Delete a single document by id
   */
  public delete(id: number): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}/${id}`)
  }

}
