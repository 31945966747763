
    import { Vue, Component } from 'vue-property-decorator'
    import { userModule } from '@/store'

@Component({
    components: {}
})
export default class UserInfo extends Vue {
    public get isLoggedIn() {
        return userModule.user
    }
}
