













































































import {flexibleDocumentModule, projectModule} from '@/store'
import {mixins} from 'vue-class-component'
import {RawLocation, Route} from 'vue-router'
import {Component, Prop } from 'vue-property-decorator'
import {ComponentExists, IComponentExists} from '@/plugins/component-exists'

import { isFlexibleDocumentWidgetCollectionResource} from '@/helpers/FlexibleDocument'

import {FlexibleDocumentResource} from '@/models/flexibleDocument/FlexibleDocumentResource'

import {FlexibleDocumentUpdateItemAllocateUsersRequest} from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemAllocateUsersRequest'

import * as Widgets from './index'
import Modal from '@/components/modals/Default.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import {FlexibleDocumentWidgetCollectionResource} from '@/models/flexibleDocument/FlexibleDocumentWidgetCollectionResource'
import {WidgetType} from '@/models/flexibleDocument/FlexibleDocumentWidgetType'

type watchRoute = Route

@Component({
  name: 'Widget',
  components: {
    UserPicker,
    Modal,
    SmallLoader,
    ...Widgets
  }
})
export default class Widget extends mixins<IComponentExists>(ComponentExists) {

  @Prop({required: true, default: null})
  public item!: FlexibleDocumentWidgetCollectionResource

  @Prop()
  private readonly document!: FlexibleDocumentResource | null

  @Prop({default: false})
  private readonly!: boolean

  @Prop({default: false})
  private canCreate!: boolean

  @Prop({default: false})
  private canEdit!: boolean

  private deleteWarningModalShown: boolean = false
  private deleting: boolean = false

  private allocatedUsersForm: { form: FlexibleDocumentUpdateItemAllocateUsersRequest, loading: boolean } = {
    loading: false,
    form: new FlexibleDocumentUpdateItemAllocateUsersRequest(this.item)
  }

  private get edit(): boolean {
    return this.$route.query.editing === this.item.uuid
  }

  private get suggest(): boolean {
    return this.$route.query.suggesting === this.item.uuid
  }

  // When another element is open or in editing mode make this item inactive
  private get isInActive(): boolean {
    const EDITING_OR_OPEN_ELEMENT = this.$route.query.editing || this.$route.params.element_id
    return typeof EDITING_OR_OPEN_ELEMENT === 'string' && EDITING_OR_OPEN_ELEMENT !== this.item.uuid
  }

  private get showActionbar(): boolean {
    // only show items when it's not readonly, is not editing
    return !this.readonly && !this.edit && !this.suggest
  }


  private get isFooterWidget(): boolean {
    return this.item.widget_type === 'Footer'
  }

  private get availableComponents(): string[] {
    return Object.getOwnPropertyNames(this.$options.components) ?? []
  }

  private get canWidgetBeSuggested(): boolean {
    const ALLOWED_SUGGESTION_WIDGETS: WidgetType[] = ['Paragraph', 'Footer']
    return ALLOWED_SUGGESTION_WIDGETS.includes(this.item.widget_type)
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get commentRoute(): RawLocation {
    return {
      name: 'projects-flexible-document-element',
      params: {element_id: this.item?.uuid ?? '', project_id: `${this.$route.params?.project_id ?? ''}`},
      query: {page: 'comments', side: 'true'}
    }
  }

  private get active(): boolean {
    return this.$route.params.element_id === this.item.uuid || this.edit || this.suggest
  }

  private get widgetType(): string {
    return isFlexibleDocumentWidgetCollectionResource(this.item) ? `${this.item.widget_type}Widget` : ''
  }

  private get widgetExists(): boolean {
    return this.availableComponents.includes(this.widgetType)
  }

  private get focusExists(): boolean {
    return this.$route.params.element_id != null || this.$route.params.proposal_id != null
  }

  private get canDoAnything(): boolean {
    return !this.readonly && !this.item.is_locked
  }

  private get isEditableWidget(): boolean {
    return this.item.widget_type !== 'Toc'
  }

  private get canStartEdit(): boolean {
    return this.item.canPerformAction('can_edit_element') && this.canDoAnything && this.canEdit && this.isEditableWidget
  }

  private get canStartSuggest(): boolean {
    return this.item.canPerformAction('can_edit_element') && this.canDoAnything && this.isEditableWidget && this.canWidgetBeSuggested
  }

  private beforeDestroy() {
    this.item.destroy()
  }

  private editing(val: boolean): void {
    this.$emit('editing', val)
  }

  private async toggleLock(): Promise<void> {
    this.item.enableLoading()
    try {
      await this.item.lock()
    } finally {
      this.item.disableLoading()
    }
  }

  private get isOnElement(): boolean {
    return this.$route.params.element_id === this.item.uuid
  }

  // Everytime we open the dropdown we set the form since the data could be changes due to websocket updates
  private setAllocationForm() {
    this.allocatedUsersForm.form = new FlexibleDocumentUpdateItemAllocateUsersRequest(this.item)
  }

  private async cancelAllocation(toggleDropdown: () => void): Promise<void> {
    this.allocatedUsersForm.form = new FlexibleDocumentUpdateItemAllocateUsersRequest(this.item)
    toggleDropdown()
  }

  private async allocateUsers(toggleDropdown: () => void): Promise<void> {
    this.allocatedUsersForm.loading = true
    try {
      await this.item.allocateUsers(this.allocatedUsersForm.form)
      toggleDropdown()
    } catch (e) {
      console.error(e)
    } finally {
      this.allocatedUsersForm.loading = false
    }
  }

  private confirmDelete(): void {
    this.deleteWarningModalShown = true
  }

  private cancelDelete(): void {
    this.deleteWarningModalShown = false
  }

  private async deleteWidget(): Promise<void> {
    this.deleting = true
    try {
      await this.item.delete()
      this.deleteWarningModalShown = false
      // Refresh flex doc to fetch all items again
      this.$root.$emit('refreshDocumentHard')

      // this.refreshDocument()
      
      if (this.isOnElement) {
        this.$router.push({name: 'projects-flexible-document', params: {project_id: this.$route.params.project_id ?? ''}})
      }
    } finally {
      this.deleting = false
    }
  }

  private mounted() {
    
  }

  private startEditing(): void {
    this.item.enableEdit()
    this.$router.replace({name: this.$route.name ?? '', params: this.$route.params, query: {editing: this.item.uuid}})
  }

  private startSuggesting(): void {
    this.item.enableSuggest()
    this.$router.replace({name: this.$route.name ?? '', params: this.$route.params, query: {suggesting: this.item.uuid}})
    this.item.revertComponents()
  }

  private async refreshDocument() {
    console.log('refresh widget')
    await flexibleDocumentModule.flexibleDocument?.refresh()
    await this.document?.refresh()

    setTimeout(() => {
      this.$root.$emit('deleteWidgetEvent')
    }, 1000)
  }
}
